import Cookies from 'js-cookie';


const SELECTORS = {
    container: '[data-cookie]',
    close: '[data-close]'
}

const Default = {
    cookieName: '',
    cookieExpTime: 365,
    delay: 0
}


class CookieBox {

    constructor(element) {

        this.cacheDOM(element);
        this.getConfig();
        this.bindEvents();

        if (!this.config.cookieName || !this.config.cookieExpTime) {
            return;
        }

        setTimeout(() => {

            this.show();

        }, this.config.delay)
    }

    cacheDOM(element) {

        this.element = $(element);
        this.closeBtn = this.element.find('[data-close]');

    }
    getConfig() {

        var dataConfig = this.element.data();

        if (dataConfig.animationOptions) {
            this.dataOptions = util.parseDataOptions(dataConfig.animationOptions);
        } else {
            this.dataOptions = {};
        }

        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }
    bindEvents() {

        this.closeBtn.on('click', () => this.hide());

    }

    show() {

        let CookieBox = Cookies.get(this.config.cookieName);
        if (CookieBox != 'hidden') {
            this.element.addClass('is-active');

        }


    }
    hide() {

        this.element.fadeOut();
        Cookies.set(this.config.cookieName, 'hidden', {
            expires: this.config.cookieExpTime
        });

    }
}



export default {
    init() {

        $(SELECTORS.container).each(function () {
            new CookieBox(this);
        });

    }
}
